<template>
  <div>
    <div class="text-right mb-2">
      <b-button
        variant="primary"
        @click="openAddonForm()"
      >
        Add Addon
      </b-button>
    </div>
    <b-card no-body>
      <!--table top-->
      <div class="m-2">
        <b-row class="justify-content-end">
          <!--search-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchQuery"
                class="mr-1"
                placeholder="Search..."
                @input="debounceSearch(meta.offset= 0, getAddonsList)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <addons-form /> -->
      <b-table
        id="tourTokenizer"
        :items="addonList"
        :fields="tableColumns"
        :busy="isTableLoading"
        primary-key="id"
        empty-text="No matching records found"
        responsive
        show-empty
        class="position-relative"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(three_dot)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            class="action-menu"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="openAddonForm(data.item)">
              <feather-icon icon="Edit2Icon" />
              <span
                class="align-middle ml-50"
              >
                Edit
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(addon_price)="data">
          {{ data.item.currency_code }} {{ Number(data.item.price).toLocaleString() }}
        </template>
      </b-table>
      <TablePagination
        :limit="meta.limit"
        :list-data="addonList"
        :total="meta.total"
        @paginate="paginate"
      />
      <AddonForm
        :addon-info="addonInfo"
        @getAddonDetails="getAddonsList"
        @resetData="resetData"
      />
    </b-card>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import AddonForm from './AddonForm.vue'
import TablePagination from '@/components/common/TablePagination.vue'

export default {
  name: 'AddonList',
  components: {
    AddonForm,
    TablePagination,
  },
  mixins: [helper, calendar],
  data() {
    return {
      addonList: [],
      isTableLoading: false,
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      // Table Handlers
      tableColumns: [
        { key: 'three_dot', label: '', thClass: 'sm' },
        { key: 'name', thClass: 'md' },
        { key: 'max_capacity', thClass: 'sm' },
        { key: 'unit', thClass: 'sm' },
        { key: 'addon_price', label: 'Price', thClass: 'md' },
        {
          key: 'tour', thClass: 'md', label: 'Tour', formatter: value => (value && value.name ? value.name : 'N/A'),
        },
      ],
      requestType: '',
      addonInfo: {},
      searchQuery: '',
    }
  },
  computed: {
    filterQuery() {
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      return `${search}`
    },
  },
  mounted() {
    this.getAddonsList()
  },
  methods: {
    getAddonsList() {
      this.isTableLoading = true
      api.getData(`tour/admin_services/addons_list/?offset=${this.meta.offset}${this.filterQuery}`, true)
        .then(response => {
          if (response.data.status) {
            this.addonList = response.data.result.results
            this.meta = response.data.result.meta
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry something went wrong')
        }).finally(() => {
          this.isTableLoading = false
        })
    },
    paginate(page) {
      this.meta.offset = (page - 1) * 10
      this.getAddonsList()
    },
    openAddonForm(data) {
      if (data) {
        this.addonInfo = data
      } else {
        this.addonInfo = {}
      }
      this.requestType = 'addon-form'
      this.$root.$emit('bv::toggle::collapse', 'addon-form')
    },
    resetData() {
      this.requestType = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
